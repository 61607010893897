<template>
  <!-- 全局常量页面 -->
  <div class="padding24 color_white" id="global_constants">
    <div class="operation-box">
      <a-button type="primary" @click="editClick()">添加常量</a-button>
      <a-button type="primary" @click="refresh">刷新缓存</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="tabledData"
      :pagination="false"
      :loading="tableLoading"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ (pageNo - 1) * pageSize + index + 1 }}</span>
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a @click="editClick(record)">编辑</a>
      </span>
    </a-table>
    <MyPagination
        :count="total"
        :pageNo="pageNo"
        @showSizeChangeFn="paginationCallBack"
        v-show="!tableLoading"
      />
    <!-- 编辑弹框 -->
    <a-modal
      title="修改全局常量"
      width="60%"
      :visible="modalVisible"
      :confirmLoading="confirmLoading"
      okText="确定"
      cancelText="取消"
      :destroy-on-close="true"
      @ok="handleClick"
      @cancel="() => (modalVisible = false)"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        labelAlign="left"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="key" prop="key">
          <a-input v-model="form.key" :disabled="inputDisabled" />
        </a-form-model-item>
        <a-form-model-item label="value" prop="value">
          <a-input v-model="form.value" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getConstantResultsV2Api,
  editConstantApi,
  addConstantApi,
  flushCacheAPI
} from '@/request/api/systemManage';
import MyPagination from '@/components/pagination/MyPagination'
const columns = [
  {
    title: "序号",
    dataIndex: "number",
    key: "number",
    width: "5%",
    scopedSlots: { customRender: "number" },
  },
  {
    title: "key",
    dataIndex: "key",
    key: "key",
    width: "20%",
  },
  {
    title: "value",
    dataIndex: "value",
    key: "value",
    width: "40%",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
    width: "30%",
  },
  {
    title: "操作",
    key: "operation",
    dataIndex: "operation",
    width: "5%",
    scopedSlots: { customRender: "operation" },
  },
]
export default {
  components: { MyPagination },
  data() {
    return {
      modalVisible: false,
      tableLoading: false,
      confirmLoading: false,
      columns: columns,
      tabledData: [],
      form: {
        key: '',
        value: '',
        remark: ''
      },
      total: 0,
      pageNo: 1,
      pageSize: 20,
      inputDisabled: false,
      rules: {
        key: [{ required: true, message: '请输入key', trigger: 'blur' }],
        value: [{ required: true, message: '请输入value', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.getConstantResults()
  },
  methods: {
    async refresh() {
      const { code } = await flushCacheAPI()
      if (code !== 200) return
      this.$message.success('刷新成功')
    },
    handleClick() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.inputDisabled ? this.editConstant() : this.addConstant()
        }
      });
    },
    // 添加
    async addConstant() {
      const params = {
        keys: this.form.key,
        vals: this.form.value,
        remark: this.form.remark
      }
      const { code, msg } = await addConstantApi(params)
      if (code !== 200) return
      this.modalVisible = false
      this.$message.success('添加成功')
      this.getConstantResults();
    },
    // 编辑
    async editConstant() {
      const params = {
        id: this.form.id,
        vals: this.form.value,
        remark: this.form.remark
      }
      const { code } = await editConstantApi(params)
      if (code !== 200) return
      this.modalVisible = false;
      this.$message.success("修改成功");
      this.getConstantResults()
    },
    editClick(record = this.$options.data().form) {
      this.inputDisabled = !!record.key
      this.form = record
      this.modalVisible = true;
    },
    // 获取列表
    async getConstantResults() {
      this.tableLoading = true
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      const { data, code } = await getConstantResultsV2Api(params)
      if (code !== 200) return
      this.total = data.count
      this.tableLoading = false
      this.tabledData = data.list
    },
    // 分页功能切换的回调
    paginationCallBack(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize
      this.getConstantResults()
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
.operation-box {
  button {
    margin-right: 20px;
  }
}
</style>